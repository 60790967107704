import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { createUseContext } from 'common/lib/context';
import { MYACC_LANGUAGE } from 'common/constants';
import { setCookies, getCookies } from 'common/utils/cookies';

import { sbmClient } from '~/apolloClient';
import { useAuthContext, SIGNED_IN_STATE } from '~/context/AuthContext';

moment.locale(localStorage.getItem('i18nextLng') || 'en');

const GeoipQuery = gql`
  query GeoipQueryData {
    geoip {
      country
      countryIso
      city
    }
  }
`;

const fallbackData = {
  city: 'Monaco',
  country: 'Monaco',
  countryIso: 'MC',
};

const {
  Context: LocalisationContext,
  ContextProvider: LocalisationContextProvider,
  ContextConsumer: LocalisationContextConsumer,
  useContext: useLocalisationContext,
} = createUseContext((Provider) => ({ children }) => {
  const { authState } = useAuthContext();
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  const [isOneclickSuspenseLang, setOneclickSuspenseLang] = useState(false);
  const { i18n } = useTranslation([
    'auth',
    'contacts-form',
    'errors',
    'join-loyalty-program',
    'promo-banner',
    'edit-account',
    'login-slider',
    'foreignids-form',
    'privacy',
    'preferences',
    'preferences-form',
    'loyalty-program',
    'loyalty-card',
    'identity-form',
    'help-block',
    'exclusive-advantages',
    'edit-identity',
    'change-password',
    'advantages-block',
    'account-information',
  ]);

  const languageISO3 = useMemo(
    () => MYACC_LANGUAGE.find(({ langId }) => langId === language).langISO3,
    [language]
  );

  const initialGeoipData = getCookies('geoip');

  const changeLanguage = useCallback(
    (language) => {
      i18n.changeLanguage(language);
      setLanguage(language);
      moment.locale(language);
      // Refetch sbm queries after lang change;
      sbmClient.reFetchObservableQueries();
    },
    [i18n, setLanguage]
  );

  const [getGeoipData, { data, loading, error }] = useLazyQuery(GeoipQuery, {
    client: sbmClient,
  });

  useEffect(() => {
    if (authState !== SIGNED_IN_STATE) {
      getGeoipData();
    }
  }, [authState, getGeoipData]);

  if (authState === SIGNED_IN_STATE) {
    return (
      <Provider
        value={{
          geoip: initialGeoipData ?? fallbackData,
          language,
          languageISO3,
          changeLanguage,
          isOneclickSuspenseLang,
          setOneclickSuspenseLang,
        }}
      >
        {children}
      </Provider>
    );
  }

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  if (!loading && data?.geoip) {
    setCookies('geoip', data.geoip);
  }

  return (
    <Provider
      value={{
        geoip:
          loading || !data || initialGeoipData
            ? initialGeoipData || fallbackData
            : data.geoip,
        language,
        languageISO3,
        changeLanguage,
        isOneclickSuspenseLang,
        setOneclickSuspenseLang,
      }}
    >
      {children}
    </Provider>
  );
});

export {
  LocalisationContextConsumer,
  LocalisationContextProvider,
  useLocalisationContext,
};

export default LocalisationContext;
