import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';
import { func, string, object } from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import ToggleByName from 'common/containers/ToggleByName';
import ContentLoader from 'common/components/ContentLoader';
import DayPickerRangeControllerWrapper from 'common/components/DayPickerRangeControllerWrapper';
import Button from 'common/components/Button';
import DateControls from 'common/scenes/Filter/components/DateControls';
import BookingCalendarQuery from 'common/queries/BookingCalendarQuery';
import FilterButton from 'common/components/StickyBookingBlockComponents/FilterButton';
import getStayData from 'common/scenes/Filter/utils/getStayData';
import BookingCalculator from 'common/scenes/Filter/components/BookingCalculator';
import { useUiContext } from 'common/context/UiContext';
import { withDatesContext } from 'common/context/DatesContext';
import { withRoomsNumberContext } from 'common/context/RoomsNumberContext';
import { withPersonsContext } from 'common/context/PersonsContext';
import { isBeforeDay } from 'common/utils/dates';
import setTravelclickUrl from 'common/utils/setTravelclickUrl';
import CloseContent from 'common/components/CloseContent';

import BookingOfferPersonsSelector from './components/BookingOfferPersonsSelector';
import BookingOfferRoomsNumberSelector from './components/BookingOfferRoomsNumberSelector';
import styles from './BookingOfferHotelConfigurator.module.scss';

const cx = classNames.bind(styles);

const { REACT_APP_MYACC_ENV } = process.env;

const DATE_FROM = 'dateFrom';
const DATE_TO = 'dateTo';

const propTypes = {
  labels: object.isRequired,
  hotelId: string.isRequired,
  createOnClick: func,
  property: object.isRequired,
  ctaLabel: string.isRequired,
  langId: string.isRequired,
  client: object,
};

const defaultProps = {
  createOnClick: null,
  client: null,
};

const BookingOfferHotelConfigurator = ({
  labels,
  hotelId,
  createOnClick,
  property,
  dates: { fromDate, toDate, onDatesChange },
  roomsNumberContext,
  personsContext,
  ctaLabel,
  langId,
  client,
}) => {
  const { isDesktop } = useUiContext();

  const [isLoading, setIsLoading] = useState(false);

  const calendarDataFromDate = moment(fromDate)
    .clone()
    .subtract(2, 'months')
    .startOf('month')
    .isAfter(moment().startOf('day'))
    ? moment(fromDate)
        .clone()
        .subtract(2, 'months')
        .startOf('month')
        .format('YYYY-MM-DD')
    : moment().startOf('day').format('YYYY-MM-DD');
  const calendarDataToDate = moment(fromDate)
    .clone()
    .subtract(2, 'months')
    .startOf('month')
    .isAfter(moment().startOf('day'))
    ? moment(fromDate)
        .clone()
        .endOf('month')
        .add(2, 'months')
        .format('YYYY-MM-DD')
    : moment().startOf('day').add(4, 'months').format('YYYY-MM-DD');

  const isDateLimitEnabled =
    Math.abs(moment(fromDate).diff(toDate, 'days')) >= 90;

  const isRoomsAdultsWarning =
    personsContext.adultsAmount < roomsNumberContext.roomsNumber;

  const {
    data: calendarData,
    loading: calendarLoading,
    fetchMore,
  } = useQuery(BookingCalendarQuery, {
    variables: {
      hotel: hotelId,
      rate: property.travelclickId,
      fromDate: calendarDataFromDate,
      toDate: calendarDataToDate,
      adults: personsContext.adultsAmount,
      roomsNumber: roomsNumberContext.roomsNumber,
      children: personsContext.childrenAmount,
    },
    ...(client && { client }),
  });

  const calendarDates = calendarData?.calendar?.dates || [];
  const stayData =
    calendarDates.length > 0
      ? getStayData(calendarDates, fromDate, toDate)
      : {};

  const stayCount =
    !!fromDate && !!toDate ? toDate.diff(fromDate, 'days') : null;

  const isMinStayWarning =
    !!stayCount && stayCount < Number(property.offerMinimalStayDuration);
  const isMaxStayWarning =
    !!stayCount && stayCount > Number(property.offerMaximalStayDuration);
  const isBeforeOfferWarning = isBeforeDay(
    fromDate,
    moment(property.offerFirstReservableDate)
  );
  const isAfterOfferWarning = isBeforeDay(
    moment(property.offerPropertyEndDate),
    toDate
  );

  const isAnyOfSelectedDatesDisabled = useMemo(() => {
    if (!calendarData) {
      return true;
    }

    const fromDateDataIndex = calendarData.calendar.dates.findIndex(
      (val) => val.date === fromDate.format('YYYY-MM-DD')
    );
    const toDateDataIndex = calendarData.calendar.dates.findIndex(
      (val) => val.date === toDate.format('YYYY-MM-DD')
    );

    if (fromDateDataIndex < 0 || toDateDataIndex < 0) {
      return true;
    }

    for (let i = fromDateDataIndex; i < toDateDataIndex; i++) {
      // last day excluded because it's 'false' always
      if (calendarData.calendar.dates[i].available === false) {
        return true;
      }
    }

    return false;
  }, [calendarData, fromDate, toDate]);

  const isSubmitDisabledByDates =
    isAnyOfSelectedDatesDisabled ||
    isMinStayWarning ||
    isMaxStayWarning ||
    isBeforeOfferWarning ||
    isAfterOfferWarning;

  const isBookingDisabled =
    isSubmitDisabledByDates ||
    isDateLimitEnabled ||
    isLoading ||
    calendarLoading ||
    isRoomsAdultsWarning;

  return (
    <ToggleByName>
      {({
        setActiveItemName,
        toggleByName,
        activeItemName: activeFilterName,
      }) => (
        <>
          <div className={cx('book-params')}>
            <ul className={cx('book-params-list')}>
              <li className={cx('list-item')}>
                <DateControls
                  withBorder
                  dateType={'fromDate'}
                  calendarData={calendarDates}
                  isActive={activeFilterName === DATE_FROM}
                >
                  <FilterButton
                    top={labels.bookingBlockLabels.checkIn}
                    middle={fromDate.format('D')}
                    bottom={fromDate.format('MMMM')}
                    onClick={toggleByName(DATE_FROM)}
                    isActive={activeFilterName === DATE_FROM}
                    className={cx('filter-button')}
                  />
                </DateControls>
                <DateControls
                  dateType={'toDate'}
                  calendarData={calendarDates}
                  isActive={activeFilterName === DATE_TO}
                >
                  <FilterButton
                    top={labels.bookingBlockLabels.checkOut}
                    middle={toDate.format('D')}
                    bottom={toDate.format('MMMM')}
                    onClick={toggleByName(DATE_TO)}
                    isActive={activeFilterName === DATE_TO}
                    className={cx('filter-button')}
                  />
                </DateControls>
                {(activeFilterName === DATE_FROM ||
                  activeFilterName === DATE_TO) && (
                  <div
                    className={cx('list-item-inner', 'list-item-inner-dates', {
                      'first-active': activeFilterName === DATE_FROM,
                      'second-active': activeFilterName === DATE_TO,
                    })}
                  >
                    {isDateLimitEnabled && (
                      <div className={cx('calendar-notification')}>
                        {labels.bookingBlockLabels.dateLimitMessage}
                      </div>
                    )}
                    {(calendarLoading || isLoading) && (
                      <ContentLoader className={cx('calendar-loader')} />
                    )}
                    <DayPickerRangeControllerWrapper
                      loadMore={fetchMore}
                      calendarData={calendarDates}
                      isMobile={!isDesktop}
                      setIsLoading={setIsLoading}
                      initialStartDate={fromDate}
                      initialEndDate={toDate}
                      numberOfMonths={isDesktop ? 2 : 1}
                      onDatesChange={onDatesChange}
                      onFocusChange={(date) => {
                        setActiveItemName(
                          date === 'endDate' ? DATE_TO : DATE_FROM
                        );
                      }}
                      focusedInput={
                        activeFilterName === DATE_FROM ? 'startDate' : 'endDate'
                      }
                      className={cx('calendar')}
                      onOutsideClick={toggleByName(activeFilterName)}
                    />
                    {isDesktop && (
                      <div className={cx('calendar-decor')}>
                        <div className={cx('calendar-decor-inner')} />
                      </div>
                    )}
                    {isDesktop && (
                      <div className={cx('calendar-selection')}>
                        <p className={cx('calendar-instructions')}>
                          <span className={cx('checkout-only-icon')}>*</span> -{' '}
                          {labels.bookingBlockLabels.checkoutOnly}
                        </p>
                        <BookingCalculator
                          dates={calendarDates}
                          stayData={stayData}
                          nightFromLabel={labels.bookingBlockLabels.nightFrom}
                          yourStayLabel={labels.bookingBlockLabels.stayAt}
                          yourPriceLabel={labels.bookingBlockLabels.stayPrice}
                          nightSingleLabel={
                            labels.bookingBlockLabels.nightSingle
                          }
                          nightPluralLabel={
                            labels.bookingBlockLabels.nightPlural
                          }
                        />
                      </div>
                    )}
                    {!isDesktop && (
                      <p className={cx('calendar-instructions')}>
                        <span className={cx('checkout-only-icon')}>*</span> -{' '}
                        {labels.bookingBlockLabels.checkoutOnly}
                      </p>
                    )}
                    {!isDesktop && (
                      <CloseContent
                        label={labels.globalLabels.close}
                        onClose={toggleByName(activeFilterName)}
                      />
                    )}
                  </div>
                )}
              </li>
              <li className={cx('list-item')}>
                <div className={cx('dropdown-label')}>
                  {labels.bookingBlockLabels.persons}
                </div>

                <BookingOfferPersonsSelector
                  adultLabel={labels.bookingBlockLabels.adults}
                  childLabel={labels.bookingBlockLabels.children}
                  isLabelAbove
                  onClose={toggleByName(activeFilterName)}
                />
              </li>
              <li className={cx('list-item')}>
                <div className={cx('dropdown-label')}>
                  {labels.bookingBlockLabels.rooms}
                </div>
                <BookingOfferRoomsNumberSelector
                  onClose={toggleByName(activeFilterName)}
                />
              </li>
            </ul>
          </div>
          <div className={cx('book-cta')}>
            <>
              {/* This condition required just to avoid sticky block shifting during calendar pagination. Sticky block became higher then wrapper at this moment. */}
              {!(
                activeFilterName === DATE_FROM || activeFilterName === DATE_TO
              ) && (
                <>
                  {(isDateLimitEnabled || isAnyOfSelectedDatesDisabled) &&
                    calendarData?.calendar?.noResultsMessage && (
                      <p className={cx('book-noresults')}>
                        {calendarData.calendar.noResultsMessage}
                      </p>
                    )}
                  {isRoomsAdultsWarning && (
                    <p className={cx('book-noresults')}>
                      {labels.bookingBlockLabels.roomsAdultsMessage}
                    </p>
                  )}
                  {isLoading && (
                    <ContentLoader className={cx('content-loader')} />
                  )}
                  {!isBookingDisabled && calendarDates.length > 0 && (
                    <BookingCalculator
                      sumOnly
                      dates={calendarDates}
                      stayData={stayData}
                      nightFromLabel={labels.bookingBlockLabels.nightFrom}
                      yourStayLabel={labels.bookingBlockLabels.stayAt}
                      yourPriceLabel={labels.bookingBlockLabels.stayPrice}
                      nightSingleLabel={labels.bookingBlockLabels.nightSingle}
                      nightPluralLabel={labels.bookingBlockLabels.nightPlural}
                    />
                  )}
                </>
              )}
              <Button
                disabled={isBookingDisabled}
                {...(createOnClick && { onClick: createOnClick() })}
                {...(REACT_APP_MYACC_ENV && {
                  tag: !isSubmitDisabledByDates ? 'a' : 'button',
                })}
                {...{
                  [!REACT_APP_MYACC_ENV ? 'to' : 'href']: setTravelclickUrl({
                    offerType: property.offerType,
                    travelclickId: property.travelclickId,
                    travelclickPackageId: property.travelclickPackageId,
                    hotelId: property.parentProperty.travelclickId,
                    langId,
                    travelclickUrlToken: property.travelclickUrlToken,
                    rooms: roomsNumberContext.roomsNumber,
                    adults: personsContext.adultsAmount,
                    children: personsContext.childrenAmount,
                    dateIn: fromDate.format('MM/DD/YYYY'),
                    dateOut: toDate.format('MM/DD/YYYY'),
                  }),
                }}
              >
                {ctaLabel}
              </Button>
            </>
          </div>
        </>
      )}
    </ToggleByName>
  );
};

BookingOfferHotelConfigurator.propTypes = propTypes;
BookingOfferHotelConfigurator.defaultProps = defaultProps;

export default flowRight(
  withDatesContext,
  withRoomsNumberContext,
  withPersonsContext
)(BookingOfferHotelConfigurator);
