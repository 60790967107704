import React from 'react';
import { string } from 'prop-types';

import { withErrorBoundary } from 'common/containers/ErrorBoundary';
import HeroInfo from 'common/components/HeroInfo';

const propTypes = {
  title: string.isRequired,
  subtitle: string,
};

const defaultProps = {
  subtitle: null,
};

const OfferHero = ({ title, subtitle }) => {
  return <HeroInfo title={title} subtitle={subtitle} subtitleFirst />;
};

OfferHero.propTypes = propTypes;
OfferHero.defaultProps = defaultProps;

export default withErrorBoundary(OfferHero);
