import React, { useState, useRef } from 'react';
import { object, oneOf } from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';

import { withErrorBoundary } from 'common/containers/ErrorBoundary';
import Container from 'common/components/Container';
import HighlightedText from 'common/components/HighlightedText';
import ProductIntro from 'common/components/ProductIntro';
import ServicesList from 'common/components/ServicesList';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';
import Wysiwyg from 'common/components/Wysiwyg';

import styles from './OfferMainInfo.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: object.isRequired,
  dataSettings: object.isRequired,
  bgColor: oneOf(['black-pearl', 'light-gray']),
};

const defaultProps = {
  bgColor: null,
};

const OfferMainInfo = ({
  data: {
    validityPeriod,
    introTitle,
    introText,
    offerAttributes,
    serviceTitle,
    services,
    moreServicesLabel,
    lessServicesLabel,
    conditionsLabel,
    conditionsTitle,
    conditionsText,
  },
  bgColor,
  dataSettings: { globalLabels },
}) => {
  const nodeRef = useRef(null);

  const [expandedServicesStatus, setExpandedServicesStatus] = useState(false);
  const [expandedConditionsStatus, setExpandedConditionsStatus] =
    useState(false);

  const changeServicesStatus = (status) => () => {
    setExpandedServicesStatus(status);
  };

  const conditionsToggle = () => {
    setExpandedConditionsStatus(!expandedConditionsStatus);
  };

  return (
    <div className={cx('root')}>
      <Container width={1140}>
        <div className={cx('content-wrapper')}>
          <div className={cx('intro-wrapper')}>
            <ProductIntro
              title={introTitle}
              size={'xl'}
              attributes={offerAttributes}
              text={introText}
              globalLabels={globalLabels}
              period={validityPeriod}
              bgColor={bgColor}
              bottomSeparated
            />
          </div>

          {!!serviceTitle && (
            <div className={cx('services-title')}>
              <strong>
                <HighlightedText index={serviceTitle.index}>
                  {serviceTitle.value}
                </HighlightedText>
              </strong>
            </div>
          )}

          {!!services && (
            <div className={cx('service-list-wrapper')}>
              <ServicesList
                servicesList={services}
                listExpanded={expandedServicesStatus}
              />
            </div>
          )}

          {(services.length > 8 || conditionsText) && (
            <div className={cx('links-wrapper')}>
              {services.length > 8 && (
                <div className={cx('link-item')}>
                  {!expandedServicesStatus ? (
                    <button onClick={changeServicesStatus(true)}>
                      {moreServicesLabel}
                    </button>
                  ) : (
                    <button onClick={changeServicesStatus(false)}>
                      {lessServicesLabel}
                    </button>
                  )}
                </div>
              )}
              {conditionsText && (
                <div className={cx('link-item')}>
                  <button onClick={conditionsToggle}>{conditionsLabel}</button>
                </div>
              )}
            </div>
          )}
        </div>
      </Container>

      {conditionsText && (
        <CSSTransition
          in={expandedConditionsStatus}
          timeout={300}
          classNames={{
            enter: cx('conditions-transition-enter'),
            enterActive: cx('conditions-transition-enter-active'),
            enterDone: cx('conditions-transition-done-enter'),
            exit: cx('conditions-transition-exit'),
            exitActive: cx('conditions-transition-exit-active'),
          }}
          unmountOnExit
          nodeRef={nodeRef}
        >
          <div ref={nodeRef} className={cx('conditions')}>
            <Container width={1140}>
              <div className={cx('content-wrapper')}>
                <div className={cx('conditions-header')}>
                  <div className={cx('conditions-title')}>
                    {conditionsTitle}
                  </div>
                  <button
                    className={cx('conditions-close')}
                    onClick={conditionsToggle}
                  >
                    <IconContainer className={cx('icon')} icon={IconClose} />
                    {globalLabels.close}
                  </button>
                </div>
                <div className={cx('conditions-content')}>
                  <Wysiwyg text={conditionsText} />
                </div>
              </div>
            </Container>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};

OfferMainInfo.propTypes = propTypes;
OfferMainInfo.defaultProps = defaultProps;

export default withErrorBoundary(OfferMainInfo);
