import React, { useRef } from 'react';
import { string, bool, array, func, node, object, oneOfType } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';

import Stars from 'common/components/Stars';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './PropertyDropdown.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isVisible: bool.isRequired,
  toggleDropdown: func.isRequired,
  label: string.isRequired,
  setProperty: func.isRequired,
  properties: array.isRequired,
  property: oneOfType([node, object]),
};

const defaultProps = {
  property: null,
};

const PropertyDropdown = function ({
  isVisible,
  toggleDropdown,
  label,
  setProperty,
  properties,
  property,
}) {
  const nodeRef = useRef(null);

  const onSelect = (item) => {
    toggleDropdown();
    setProperty(item);
  };

  const renderPropertyLabel = (item) => (
    <>
      <span className={cx('property-title')}>{item.title}</span>
      {!!item.stars && (
        <Stars value={item.stars.value} className={cx('hotel-stars')} />
      )}
    </>
  );
  return (
    <div className={cx('root')}>
      {properties.length === 1 ? (
        <div className={cx('dropdown-single-item')}>
          {renderPropertyLabel(properties[0].parentProperty)}
        </div>
      ) : (
        <>
          <button
            type="button"
            onClick={toggleDropdown}
            className={cx('dropdown-opener')}
          >
            {!!property ? renderPropertyLabel(property.parentProperty) : label}
          </button>
          <CSSTransition
            in={isVisible}
            timeout={300}
            classNames={{
              enter: cx('dropdown-transition-enter'),
              enterActive: cx('dropdown-transition-enter-active'),
              enterDone: cx('dropdown-transition-done-enter'),
              exit: cx('dropdown-transition-exit'),
              exitActive: cx('dropdown-transition-exit-active'),
            }}
            unmountOnExit
            nodeRef={nodeRef}
          >
            <div ref={nodeRef} className={cx('dropdown-content')}>
              <button
                onClick={toggleDropdown}
                type="button"
                className={cx('dropdown-content-header')}
              >
                {label}
              </button>
              <ul className={cx('dropdown-list')}>
                {properties.map((item, index) => (
                  <li key={index} className={cx('list-item')}>
                    <button
                      onClick={() => onSelect(item)}
                      type="button"
                      className={cx('list-item-btn', {
                        active:
                          property?.parentProperty.id ===
                          item.parentProperty.id,
                      })}
                    >
                      {renderPropertyLabel(item.parentProperty)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </CSSTransition>
        </>
      )}
    </div>
  );
};

PropertyDropdown.propTypes = propTypes;
PropertyDropdown.defaultProps = defaultProps;

export default withErrorBoundary(PropertyDropdown);
