import { useMemo } from 'react';

import { UNDEFINED } from '../constants';

export function useGdpr(userGdpr, loadingUserData) {
  const userSubscribedToNewsletter = useMemo(() => {
    if (userGdpr && !loadingUserData) {
      return userGdpr[0]?.value || UNDEFINED;
    }

    return UNDEFINED;
  }, [userGdpr, loadingUserData]);

  return { userSubscribedToNewsletter };
}
