import React, { useState, useEffect, useRef } from 'react';
import { string, object, func, arrayOf } from 'prop-types';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { gql } from '@apollo/client';
import moment from 'moment';
import { Field } from 'formik';

import DayPickerSingleControllerWrapper from 'common/components/DayPickerSingleControllerWrapper';
import CloseContent from 'common/components/CloseContent';
import Radio from 'common/components/FormComponents/Radio';
import listenForOutsideClicks from 'common/utils/listenForOutsideClicks';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useLocalisationContext } from '~/context/LocalisationContext';

import TopFiltersItem from '../TopFiltersItem';

import styles from './WellnessDateParams.module.scss';

const cx = classNames.bind(styles);

const DATE_FROM = 'dateFrom';
const DAY_TIME = 'dayTime';

const dayTimeListFieldsFragment = gql`
  fragment dayTimeListFields on ListValue {
    label
    value
  }
`;

const propTypes = {
  dateLabel: string.isRequired,
  setFieldValue: func.isRequired,
  initialDate: object.isRequired,
  updateDate: func.isRequired,
  dayTimeLabel: string.isRequired,
  dayTime: arrayOf(propType(dayTimeListFieldsFragment).isRequired).isRequired,
  dayTimeValue: string.isRequired,
  closeLabel: string.isRequired,
};

const WellnessDateParams = function ({
  dateLabel,
  setFieldValue,
  initialDate,
  updateDate,
  dayTimeLabel,
  dayTime,
  dayTimeValue,
  closeLabel,
}) {
  const paramsRef = useRef(null);
  const nodeRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [date, setDate] = useState(initialDate);
  const [activeItemName, setActiveItemName] = useState();
  const { language } = useLocalisationContext();

  useEffect(() => {
    if (date) {
      updateDate(date);
    }
  }, [date, updateDate]);

  useEffect(() => {
    if (!dayTime.find((item) => item.value === dayTimeValue)) {
      setFieldValue('daytime', dayTime[0].value);
    }
  }, [dayTime, dayTimeValue, setFieldValue]);

  const toggleByName = (name) => {
    if (name && activeItemName !== name) {
      setActiveItemName(name);
    } else {
      setActiveItemName(null);
    }
  };

  useEffect(
    listenForOutsideClicks(listening, setListening, paramsRef, toggleByName)
  );

  return (
    <div className={cx('root')}>
      <ul className={cx('book-params-list')} ref={paramsRef}>
        <li className={cx('list-item')}>
          <TopFiltersItem
            top={dateLabel}
            middleIsNum={true}
            middle={date.format('D')}
            bottom={date.locale(language).format('MMMM')}
            onClick={() => toggleByName(DATE_FROM)}
            isActive={activeItemName === DATE_FROM}
          />
          <CSSTransition
            in={activeItemName === DATE_FROM}
            timeout={150}
            classNames={{
              enter: cx('transition-enter'),
              enterActive: cx('transition-enter-active'),
              enterDone: cx('transition-done-enter'),
              exit: cx('transition-exit'),
              exitActive: cx('transition-exit-active'),
            }}
            unmountOnExit
            nodeRef={nodeRef}
          >
            <div
              ref={nodeRef}
              className={cx('list-item-inner', 'first-active')}
            >
              <DayPickerSingleControllerWrapper
                initialDate={date}
                onDateChange={setDate}
                onFocusChange={() => toggleByName(DAY_TIME)}
                datesStartRange={moment().locale(language).format('YYYY-MM-DD')}
              />
              <CloseContent label={closeLabel} onClose={() => toggleByName()} />
            </div>
          </CSSTransition>
        </li>

        <li className={cx('list-item')}>
          <TopFiltersItem
            top={dayTimeLabel}
            withArrow={true}
            middle={dayTime.find((item) => item.value === dayTimeValue)?.label}
            onClick={() => toggleByName(DAY_TIME)}
            isActive={activeItemName === DAY_TIME}
          />
          <CSSTransition
            in={activeItemName === DAY_TIME}
            timeout={150}
            classNames={{
              enter: cx('transition-enter'),
              enterActive: cx('transition-enter-active'),
              enterDone: cx('transition-done-enter'),
              exit: cx('transition-exit'),
              exitActive: cx('transition-exit-active'),
            }}
            unmountOnExit
          >
            <div
              className={cx('list-item-inner', {
                'second-active': activeItemName === DAY_TIME,
              })}
            >
              <div className={cx('day-time-wrapper')}>
                {dayTime.map(({ value, label }) => (
                  <Field
                    key={value}
                    name="daytime"
                    component={Radio}
                    id={value}
                    label={label}
                  />
                ))}
              </div>
              <CloseContent label={closeLabel} onClose={() => toggleByName()} />
            </div>
          </CSSTransition>
        </li>
      </ul>
    </div>
  );
};

WellnessDateParams.propTypes = propTypes;

WellnessDateParams.fragments = {
  dayTimeListFieldsFragment,
};

export default withErrorBoundary(WellnessDateParams);
