import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { bool, string, func } from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';

import { MYACC_LANGUAGE } from 'common/constants';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useMyaccHistory } from '~/hooks';

import styles from './LangSwitcher.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isOpen: bool.isRequired,
  className: string,
  onButtonClick: func.isRequired,
};

const defaultProps = {
  className: '',
};

const getCorrespondingLang = (lang) =>
  (lang &&
    MYACC_LANGUAGE.find((langItem) => langItem.langId === lang.slice(0, 2))) ||
  'en';

const LangSwitcher = forwardRef(({ className, onButtonClick, isOpen }, ref) => {
  const nodeRef = useRef(null);
  const history = useMyaccHistory();
  const initialLang = localStorage.getItem('i18nextLng');
  const [activeLang, setActiveLang] = useState(
    getCorrespondingLang(initialLang)
  );

  const {
    location: { pathname, search },
  } = history;

  const { language: profileLanguage, changeLanguage } =
    useLocalisationContext();

  useEffect(() => {
    if (profileLanguage) {
      setActiveLang(getCorrespondingLang(profileLanguage));
    }
  }, [profileLanguage]);

  const onLanguageChange = (langId) => () => {
    changeLanguage(langId);
    onButtonClick();

    history.replace(
      `/${langId}${
        pathname.slice(3).length > 0 ? pathname.slice(3) : ''
      }${search}`
    );
  };

  const renderMenuItem = ({ langId, title }, index) => (
    <li
      className={cx('lang-menu-item', {
        'is-active': activeLang.langId === langId,
      })}
      key={'LangMenu-item-' + index}
    >
      <button
        onClick={onLanguageChange(langId)}
        type="button"
        className={cx('lang-menu-item-button')}
      >
        {title}
      </button>
    </li>
  );

  return (
    <div className={cx('root', className)} ref={ref}>
      <button className={cx('lang-menu-trigger')} onClick={onButtonClick}>
        {activeLang.shortTitle}
      </button>

      <CSSTransition
        in={isOpen}
        timeout={100}
        classNames={{
          enter: cx('transition-enter'),
          enterActive: cx('transition-enter-active'),
          enterDone: cx('transition-done-enter'),
          exit: cx('transition-exit'),
          exitActive: cx('transition-exit-active'),
        }}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div ref={nodeRef} className={cx('lang-menu-container')}>
          <button className={cx('lang-menu-close')} onClick={onButtonClick}>
            <span className={cx('line')} />
            <span className="screen-reader">Close</span>
          </button>
          <ul>{MYACC_LANGUAGE.map(renderMenuItem)}</ul>
        </div>
      </CSSTransition>
    </div>
  );
});

LangSwitcher.propTypes = propTypes;
LangSwitcher.defaultProps = defaultProps;

export default LangSwitcher;
